import Vue from 'vue';
import App from './App.vue';
import { router } from './router.js';
import { LayoutPlugin } from 'bootstrap-vue';

Vue.use(LayoutPlugin);

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
