import Vue from 'vue';
import Router from 'vue-router';
import Routes from '@/routes';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: Routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    else {
        document.title = 'Honey'
    }

    next();
});
