<template>
    <div id="app" class="app" :class="[pageClass]">
        <router-view />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/site.css';

export default {
    name: 'App',
    components: {},

    computed: {
        pageClass() {
            return this.$route.meta.pageClass;
        }
    }
};
</script>

<style>
#app {
}
</style>
