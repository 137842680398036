const HomeView = () => import('@/views/home-view');
const UnsubscribeView = () => import('@/views/unsubscribe-view');

export default [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: {
            title: 'An error has occurred',
            pageClass: 'home-page'
        }
    },
    {
        path: '/Unsubscribe',
        name: 'Unsubscribe',
        component: UnsubscribeView,
        meta: {
            title: 'Unsubscribe',
            pageClass: 'unsubscribe-page'
        }
    }
];
